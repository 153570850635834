import React from "react";
import { Box, Text, Heading, Button } from "@chakra-ui/core";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import Layout from "gatsby-plugin-based-on-staticfuse/src/components/Layout";
import SEO from "gatsby-plugin-based-on-staticfuse/src/components/SEO";
import NewsGrid from "../../gatsby-plugin-based-on-staticfuse/components/NewsGrid";
import useSiteMetadata from "gatsby-plugin-based-on-staticfuse/src/hooks/use-site-metadata";

const Home = ({ location }) => {
  const { title } = useSiteMetadata();
  const data = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "images/illustration_blanke_watercolor.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, srcSetBreakpoints: [500, 1000, 1500]) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      pageContent: wpgraphql {
        text: pageBy(pageId: 125) {
          slug
          content
        }
      }
    }
  `);

  return (
    <Layout location={{ location }} fullWidth="true">
      <SEO title="Home" />

      <Box bg="#fff" mb={4} pb={8}>
        <Box
          m="auto"
          display={["block", "block", "flex"]}
          maxW="4xl"
          justifyContent="space-between"
          alignItems="center"
          pt={[0, 0, 4, 8]}
        >
          <Box
            w={["100%", "100%", "60%"]}
            maxW={"500px"}
            float={["none", "none", "right"]}
            p={2}
            m="auto"
            order={["1", "1", "2"]}
          >
            <div style={{ position: "relative" }}>
              <Img
                fluid={{
                  ...data.illustration.childImageSharp.fluid,
                  sizes: "500px",
                }}
              />
              <small
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  transform: "translateY(-32px) rotate(90deg) ",
                  fontSize: "0.5rem",
                }}
              >
                ©{" "}
                <a
                  href="https://www.arcomul.nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Arco Mul
                </a>
              </small>
            </div>
          </Box>

          <Box w={["100%", "100%", "55%"]} color="#fff" mb={[6, 6, "0"]}>
            <Heading
              as="h1"
              fontWeight="600"
              fontSize={["2rem", "2.5rem", "3.2rem"]}
              color="primary"
              lineHeight="1.1"
            >
              {title}
            </Heading>
            <Text color="gray.500" fontSize="md" mb={8} fontWeight="500">
              CREATE Lab at University of Amsterdam
            </Text>
            <Button
              _hover={{ bg: "buttonBgHover" }}
              onClick={() => navigate("/share/")}
              bg="buttonBg"
              color="buttonText"
              rightIcon="arrow-forward"
              w={["100%", "auto"]}
              mb={[2, "0"]}
              rounded="full"
              maxW={"300px"}
            >
              Share your story
            </Button>
            <Button
              onClick={() => navigate("/blog/")}
              variantColor="gray"
              color="gray.600"
              ml={["0", 4]}
              w={["100%", "auto"]}
              variant="outline"
              rounded="full"
              maxW={"300px"}
            >
              Read our blog
            </Button>
          </Box>
        </Box>
      </Box>

      <Box maxW="4xl" m="auto" px={2}>
        <Box my="4rem">
          <Heading lineHeight="1.2" fontSize="2xl" color="#2d3748">
            What is this project about?
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: data.pageContent.text.content,
            }}
          ></div>
          <Button
            onClick={() => navigate("/nl/about/")}
            rounded="full"
            variant="outline"
            variantColor="#004E92"
          >
            Read more
          </Button>
        </Box>
      </Box>

      <Box maxW="4xl" mx="auto" my={8} px={2} mb={24}>
        <Heading as="h4" color="gray.400" fontSize="lg">
          In the news
        </Heading>
        <NewsGrid limit="3" noPadding />
      </Box>
    </Layout>
  );
};

export default Home;
